import React, { useState, useEffect } from 'react';
import './Header.css';

export default function Header({ onNavClick }) {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest('.navbar')) {
        setMenuActive(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  // We'll map each nav item to setActivePage accordingly
  return (
    <header className="container">
      <nav className="navbar">
        <div className="navbar-left">
          {/* Clicking the logo goes "home" */}
          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              onNavClick('home');
            }}
          >
            <img src="aircity-logo.png" alt="Aircity Logo" />
          </a>
        </div>

        <button className="mobile-menu-btn" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>

        <div className={`navbar-right ${menuActive ? 'active' : ''}`}>
          <a
            href="#research"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              onNavClick('research');
            }}
          >
            Research
          </a>

          <a
            href="#services"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              onNavClick('services');
            }}
          >
            Services
          </a>

          <a
            href="#safety"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              onNavClick('home'); 
              // or create a separate 'safety' page, if you prefer
              // onNavClick('safety')
            }}
          >
            Safety
          </a>

          <a
            href="#company"
            onClick={(e) => {
              e.preventDefault();
              closeMenu();
              onNavClick('home'); 
              // or if you want a separate 'company' page
              // onNavClick('company')
            }}
          >
            Company
          </a>
        </div>
      </nav>
    </header>
  );
}
