import React from 'react';
import './Card.css';

export default function Card({ header, imageUrl, title, description }) {
  return (
    <div className="card">
      <div 
        className="card-image" 
        style={{
          backgroundImage: `url('${imageUrl}')`,
        }}
      />
      {header && <div className="card-header">{header}</div>}
      <div className="card-content">
        {title && <h3 className="card-title">{title}</h3>}
        {description && <p className="card-description">{description}</p>}
      </div>
    </div>
  );
}
