import React from 'react';
import CardGrid from './CardGrid';
import Card from './Card';

export default function ServiceSection() {
  return (
    <section className="card-section container" id="services">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem', marginTop: '2rem' }}>
        <h2 className="section-title" style={{ margin: 0 }}>Services</h2>
        <a
          href="services.html"
          style={{
            marginLeft: '1rem',
            textDecoration: 'underline',
            fontSize: '1rem',
            color: '#fff',
            transition: 'color 0.2s ease-in-out'
          }}
        >
          Discover Our Platform →
        </a>
      </div>

      <CardGrid>
        <Card
          header="Transit Access"
          imageUrl="mass-transit-app.png"
          title="Mass Transit Car App"
          description="A user-friendly mobile solution for scheduling rides, checking real-time routes, and managing daily commutes effortlessly."
        />
        <Card
          header="Next-Gen Mobility"
          imageUrl="touchless-pass.png"
          title="Touchless Pass Transit Network"
          description="A frictionless ticketing and access system that unifies station entries and vehicle transfers—no physical passes required."
        />
        <Card
          header="Rapid EV Deployment"
          imageUrl="rapid-ev-deploy.png"
          title="Rapid Delivery of EVs"
          description="Enabling the swift provisioning of full-electric vehicles to critical stations—paving the way for future autonomous fleets."
        />
        <Card
          header="Community-Funded Fleet"
          imageUrl="decentralized-fleet.png"
          title="Decentralized EV Ownership"
          description="Invest in our shared fleet to earn stable returns while helping optimize eco-friendly mobility for the entire community."
        />
      </CardGrid>
    </section>
  );
}
