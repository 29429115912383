import React from 'react';
import './Footer.css';

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-main">
          <div className="footer-section">
            <h3>Platform</h3>
            <nav>
              <a href="#research">Research</a>
              <a href="#services">Services</a>
              <a href="#safety">Safety</a>
              <a href="#company">Company</a>
            </nav>
          </div>
          <div className="footer-section">
            <h3>Resources</h3>
            <nav>
              <a href="/documentation">Documentation</a>
              <a href="/press">Press Kit</a>
              <a href="/blog">Blog</a>
              <a href="/careers">Careers</a>
            </nav>
          </div>
          <div className="footer-section">
            <h3>Legal</h3>
            <nav>
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms of Service</a>
              <a href="/security">Security</a>
              <a href="/compliance">Compliance</a>
            </nav>
          </div>
          <div className="footer-section footer-contact">
            <h3>Contact</h3>
            <p>15 Auto Plaza</p>
            <p>Kowloon, HK</p>
            <p>
              <a href="mailto:contact@aircity.com">contact@aircity.com</a>
            </p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {year} Aircity. All rights reserved.</p>
          <p className="footer-location">Hong Kong · Amsterdam · London </p>
        </div>
      </div>
    </footer>
  );
}
