import React, { useState } from 'react';
import Header from './components/Header';
import HeroScroller from './components/HeroScroller';
import ResearchSection from './components/ResearchSection';
import ServiceSection from './components/ServiceSection';
import SafetySection from './components/SafetySection';
import CompanySection from './components/CompanySection';
import Footer from './components/Footer';
import './App.css';

function App() {
  // Track which section is currently active
  const [activePage, setActivePage] = useState('home');

  // Handle navigation between sections
  const handleNavigation = (pageName) => {
    setActivePage(pageName);
    // Scroll to top when changing pages
    window.scrollTo(0, 0);
  };

  // Render appropriate content based on active page
  const renderContent = () => {
    switch (activePage) {
      case 'home':
        return (
          <>
            <HeroScroller />
            <ResearchSection />
            <ServiceSection />
            <SafetySection />
            <CompanySection />
          </>
        );
      case 'research':
        return <ResearchSection />;
      case 'services':
        return <ServiceSection />;
      case 'safety':
        return <SafetySection />;
      case 'company':
        return <CompanySection />;
      default:
        return null;
    }
  };

  return (
    <div className="app">
      {/* Header with navigation */}
      <Header 
        onNavClick={handleNavigation}
        activePage={activePage}
      />

      {/* Main content */}
      <main>
        {renderContent()}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
