import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './HeroScroller.css';

export default function HeroScroller() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [swipeVelocity, setSwipeVelocity] = useState(0);

  const heroContainerRef = useRef(null);
  const videosRef = useRef([]);
  const lastTimeRef = useRef(Date.now());
  const animationFrameRef = useRef();
  const transitionTimeoutRef = useRef();

  // Track playback progress for each video
  const [progress, setProgress] = useState([0, 0, 0]);

  const slides = useMemo(() => [
    {
      videoSrc: 'hero1.mp4',
      title: 'Spatial Intelligence',
      desc: 'Merging photogrammetry, 3D modeling, and indoor navigation for the next evolution in city design.'
    },
    {
      videoSrc: 'hero2.mp4',
      title: 'Connected Telematics',
      desc: 'Empowering usage-based insurance and live fleet insights to elevate urban mobility.'
    },
    {
      videoSrc: 'hero3.mp4',
      title: 'Decentralized Ownership',
      desc: 'Offering investors 8%+ annual returns with shared EV fleets for a sustainable, profitable future.'
    }
  ], []);

  // Memoized navigation functions
  const goToSlide = useCallback((index, immediate = false) => {
    if (videosRef.current[currentIndex]) {
      videosRef.current[currentIndex].pause();
    }

    // Handle looping
    let nextIndex = index;
    if (index < 0) {
      nextIndex = slides.length - 1;
    } else if (index >= slides.length) {
      nextIndex = 0;
    }

    // Reset progress for new slide
    setProgress(prev => {
      const updated = [...prev];
      updated[currentIndex] = 0;
      return updated;
    });

    setCurrentIndex(nextIndex);

    // Start playing the new video
    const nextVideo = videosRef.current[nextIndex];
    if (nextVideo) {
      nextVideo.currentTime = 0;
      nextVideo.play();
    }
  }, [currentIndex, slides.length]);

  const nextSlide = useCallback(() => {
    goToSlide(currentIndex + 1);
  }, [currentIndex, goToSlide]);

  const prevSlide = useCallback(() => {
    goToSlide(currentIndex - 1);
  }, [currentIndex, goToSlide]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') prevSlide();
      if (e.key === 'ArrowRight') nextSlide();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [nextSlide, prevSlide]);

  // Video playback and visibility handling
  useEffect(() => {
    const videoEl = videosRef.current[currentIndex];
    if (!videoEl) return;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        videoEl.pause();
      } else {
        videoEl.play();
      }
    };

    videoEl.currentTime = 0;
    videoEl.play();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [currentIndex]);

  // Optimized pointer handling with requestAnimationFrame
  const handlePointerDown = useCallback((e) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setDragOffset(0);
    lastTimeRef.current = Date.now();
    
    if (heroContainerRef.current) {
      heroContainerRef.current.style.cursor = 'grabbing';
    }
  }, []);

  const updateDragOffset = useCallback((clientX) => {
    const now = Date.now();
    const dt = now - lastTimeRef.current;
    const dx = clientX - startX;
    
    setSwipeVelocity(dx / dt);
    lastTimeRef.current = now;
    setDragOffset(dx);
  }, [startX]);

  const handlePointerMove = useCallback((e) => {
    if (!isDragging) return;

    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(() => {
      updateDragOffset(e.clientX);
    });
  }, [isDragging, updateDragOffset]);

  const handlePointerUp = useCallback((e) => {
    if (!isDragging) return;
    
    setIsDragging(false);
    if (heroContainerRef.current) {
      heroContainerRef.current.style.cursor = 'grab';
    }

    const velocityThreshold = 0.5;
    if (Math.abs(swipeVelocity) > velocityThreshold) {
      swipeVelocity < 0 ? nextSlide() : prevSlide();
    } else {
      const threshold = 0.25 * window.innerWidth;
      if (dragOffset < -threshold) {
        nextSlide();
      } else if (dragOffset > threshold) {
        prevSlide();
      } else {
        goToSlide(currentIndex);
      }
    }

    setDragOffset(0);
    setSwipeVelocity(0);
  }, [isDragging, swipeVelocity, dragOffset, nextSlide, prevSlide, goToSlide, currentIndex]);

  const handlePointerLeave = useCallback(() => {
    if (!isDragging) return;
    
    setIsDragging(false);
    if (heroContainerRef.current) {
      heroContainerRef.current.style.cursor = 'grab';
    }
    setDragOffset(0);
    setSwipeVelocity(0);
  }, [isDragging]);

  // Optimized video event handlers
  const handleVideoEnded = useCallback(() => {
    nextSlide();
  }, [nextSlide]);

  const handleTimeUpdate = useCallback((idx) => {
    if (!videosRef.current[idx]) return;
    
    const video = videosRef.current[idx];
    const ratio = video.duration ? video.currentTime / video.duration : 0;
    
    setProgress(prev => {
      const updated = [...prev];
      updated[idx] = ratio;
      return updated;
    });
  }, []);

  const handleLoadedData = useCallback((idx) => {
    if (idx === currentIndex) {
      setIsLoading(false);
    }
  }, [currentIndex]);

  // Memoized transform calculation
  const containerStyle = useMemo(() => {
    const baseTranslate = -(currentIndex * 80);
    const offsetVW = (dragOffset / window.innerWidth) * 100;
    const finalTranslate = baseTranslate + offsetVW;

    return {
      transform: `translateX(${finalTranslate}vw)`
    };
  }, [currentIndex, dragOffset]);

  // Cleanup
  useEffect(() => {
    return () => {
      cancelAnimationFrame(animationFrameRef.current);
      clearTimeout(transitionTimeoutRef.current);
      videosRef.current.forEach(video => {
        if (video) video.pause();
      });
    };
  }, []);

  return (
    <>
      <section
        className="hero-scroller"
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onPointerLeave={handlePointerLeave}
        role="region"
        aria-label="Hero content slider"
      >
        <div
          className="hero-container"
          ref={heroContainerRef}
          style={containerStyle}
          onPointerDown={handlePointerDown}
          aria-roledescription="carousel"
        >
          {slides.map((slide, idx) => {
            const isActive = idx === currentIndex;
            
            return (
              <div 
                className={`hero-slide ${isActive ? 'active' : ''}`} 
                key={idx}
                role="group"
                aria-roledescription="slide"
                aria-label={`Slide ${idx + 1} of ${slides.length}: ${slide.title}`}
                aria-hidden={!isActive}
              >
                <div>
                  <video
                    src={slide.videoSrc}
                    muted
                    preload="auto"
                    playsInline
                    loop={false}
                    ref={el => (videosRef.current[idx] = el)}
                    onEnded={handleVideoEnded}
                    onTimeUpdate={() => handleTimeUpdate(idx)}
                    onLoadedData={() => handleLoadedData(idx)}
                    aria-hidden="true"
                  />
                  
                  {isActive && isLoading && (
                    <div className="loading-overlay">
                      <div className="loading-spinner" />
                    </div>
                  )}

                  <div className="banner-content">
                    <h2 className="banner-title">{slide.title}</h2>
                    <p className="banner-desc">{slide.desc}</p>
                  </div>

                  {isActive && (
                    <div 
                      className="progress-bar"
                      style={{ width: `${progress[idx] * 100}%` }}
                      role="progressbar"
                      aria-valuenow={progress[idx] * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <div 
        className="hero-dots" 
        role="tablist" 
        aria-label="Slider navigation"
      >
        {slides.map((slide, idx) => {
          const isActive = currentIndex === idx;
          const fillRatio = progress[idx] || 0;
          
          return (
            <button
              key={idx}
              className={`dot ${isActive ? 'active' : ''}`}
              onClick={() => goToSlide(idx)}
              role="tab"
              aria-selected={isActive}
              aria-label={`Go to slide ${idx + 1}: ${slide.title}`}
              aria-controls={`slide-${idx}`}
            >
              <div
                className="dot-progress"
                style={{
                  width: `${14 * fillRatio}px`,
                  height: `${14 * fillRatio}px`,
                  margin: `${(14 - 14 * fillRatio)/2}px auto 0`
                }}
              />
            </button>
          );
        })}
      </div>
    </>
  );
}
