import React from 'react';
import { ArrowRight } from 'lucide-react';
import CardGrid from './CardGrid';
import Card from './Card';

export default function ResearchSection() {
  return (
    <section className="card-section container" id="research">
      <div className="section-header">
        <div className="section-title-wrapper">
          <h2 className="section-title">Research</h2>
          <button
            onClick={() => window.location.href = '/research'}
            className="circle-button"
            aria-label="View all research"
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <CardGrid>
        <Card
          header="Spatial AI • Feb 2025"
          imageUrl="spatial-stack.png"
          title="Next-Gen Spatial Intelligence"
          description="Building meaningful spatial experiences through Gaussian splatting and immersive mapping for more intuitive navigation."
        />
        <Card
          header="Urban Intelligence • Feb 2025"
          imageUrl="parking-ai-vision.png"
          title="Reinventing Public Space Usage"
          description="Leveraging computer vision to transform how we measure and optimize public parking, creating more efficient urban spaces."
        />
        <Card
          header="Simulation • Feb 2025"
          imageUrl="driving-sim.png"
          title="Advanced Driving Simulation"
          description="Engineering precise physics-based driving simulations that deliver effective operator training and meaningful research insights."
        />
        <Card
          header="Navigation • Feb 2025"
          imageUrl="pdr-navigation.png"
          title="Seamless Indoor Navigation"
          description="Mastering indoor wayfinding through intelligent sensor fusion and spatial mapping, making complex spaces feel simple."
        />
      </CardGrid>
    </section>
  );
}
