import React from 'react';
import CardGrid from './CardGrid';
import Card from './Card';

export default function SafetySection() {
  return (
    <section className="card-section container" id="safety">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem', marginTop: '2rem' }}>
        <h2 className="section-title" style={{ margin: 0 }}>Safety</h2>
        <a
          href="safety.html"
          style={{
            marginLeft: '1rem',
            textDecoration: 'underline',
            fontSize: '1rem',
            color: '#fff',
            transition: 'color 0.2s ease-in-out'
          }}
        >
          See Safety Features →
        </a>
      </div>

      <CardGrid>
        <Card
          header="Telematics Trust"
          imageUrl="telematics-safety.png"
          title="Data-Driven Underwriting"
          description="Protecting drivers and fleets with transparent, behavior-based insurance solutions powered by telematics."
        />
        <Card
          header="Real-Time Ops"
          imageUrl="realtime-ops.png"
          title="Live Monitoring & Alerts"
          description="AI-driven dashboards that immediately flag mechanical or security risks, ensuring safer rides for everyone."
        />
      </CardGrid>
    </section>
  );
}
