import React from 'react';

export default function CompanySection() {
  return (
    <section className="card-section container" id="company">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem', marginTop: '2rem' }}>
        <h2 className="section-title" style={{ margin: 0, textAlign: 'left' }}>Company</h2>
        <a
          href="company.html"
          style={{
            marginLeft: '1rem',
            textDecoration: 'underline',
            fontSize: '1rem',
            color: '#fff',
            transition: 'color 0.2s ease-in-out'
          }}
        >
          About Our Mission →
        </a>
      </div>

      <p style={{ maxWidth: '800px', margin: '0 auto 2rem auto', textAlign: 'left' }}>
       At Aircity, we unify connected vehicles, property stations, and advanced spatial data into a single, cohesive system for modern mobility. Our platform enables motion and interactions with the immediate surroundings of every modern citizen—seamlessly orchestrating people, vehicles, and infrastructure. By harnessing AI for real-world, meaningful engagements, we shape sustainable, inclusive cities powered by deep spatial intelligence. The result is a living blueprint for next-generation transit—driving communities forward with clarity and confidence.
      </p>
    </section>
  );
}
